import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Blog from '../views/Blog.vue'
import Post from '../views/Post.vue'
import Impressum from '../views/Impressum.vue'
import createPost from '../views/createPost.vue'
import Login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta:{
      reqiuresAuth: false
    }
  },
  {
    path: '/createPost/:id?',
    name: 'createPost',
    component: createPost,
      meta:{
        reqiuresAuth: true
      }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      reqiuresAuth: false
    }
  },
  {
    path: '/post/:id',
    name: 'Post',
    component: Post,
    meta:{
      reqiuresAuth: false
    }
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
    meta:{
      reqiuresAuth: false
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from , next)=>{
  if(to.matched.some((res)=> res.meta.reqiuresAuth)){
    if (store.state.userProfile){
      return next()
    }
    return next({ name: Home})
  }
  return next();
})

export default router
