<template>
    <div class="h-full  bg-gray-100 shadow-lg rounded-lg border-gray-100 border-1 p-4  m-12  ">
     
     <div class="flex"> 
       <div class="bg-grey-lighter">
          <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
              <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span class="mt-2 text-base leading-normal">Titel Bild Hochladen</span>
              <input type='file' class="hidden" accept="image/jpeg" @change=titleImage>
          </label>
      </div>
      
   
      <button @click="savePost" class="btn"> Speichern</button>
       <img :src="blogPostForm.titleImage" alt="" class="max-h-24 object-cover">
      </div>
      <div class="text-gray-900">
          <label for="headline" class="mw_inputeLabe">Überschrift</label>
          <input v-model="blogPostForm.headline" type="text" placeholder="Überschrift" id="headline" class="mw_inpute" />
          
       </div>
    

     <vue-editor use-custom-image-handler v-model="blogPostForm.post" @image-added="imageHandler" style="height: 400px"></vue-editor>
    
      
    </div>
</template>

<script>
import { VueEditor,Quill } from "vue2-editor";


import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  created () {
    this.fetchData()
  },
components: {
    //Editor,
    VueEditor
  },
    
data(){
    return{
       editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        }
      },
      customToolbar: [
          ['bold', 'italic'],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'color': [] }, { 'background': [] }]
        ],
      blogPostForm:{},
      editor: null,
      blogPost: {},
      previewImage: null,
    }
    
},
watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
methods:{
    titleImage(files){
      const file = files.target.files[0];
      console.log(file.name)
      this.$store.dispatch("uploadTitleImage", file).then(res =>{
        console.log("Url: "+res);
        this.previewImage = res;
        this.blogPostForm.titleImage = res;
      })
      
    },
    savePost(){
      console.log(this.$route.params.id)
      this.blogPost= {
        id: this.$route.params.id,
        details: this.blogPostForm
      }
      this.$store.dispatch("createBlogPost",  this.blogPost);
  },
    fetchData(){
        if (this.$route.params.id){
          this.blogPostForm = this.$store.getters.getPost(this.$route.params.id).details

        }
      
    },
    imageHandler(file, Editor, cursorLocation, resetUploader){
      console.log(file.name)
      this.$store.dispatch("uploadImage", file).then(res =>{
        Editor.insertEmbed(cursorLocation,"image", res)
        resetUploader();
      })
      
    }
}
}
</script>

<style>

</style>