import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyCrx-qeOb03bSklD2BJuHl5Wk9ZhHpRkLA",
  authDomain: "nb-dev-dec6a.firebaseapp.com",
  projectId: "nb-dev-dec6a",
  storageBucket: "nb-dev-dec6a.appspot.com",
  messagingSenderId: "570637914870",
  appId: "1:570637914870:web:afd69712cebcf0f88252d7",
  measurementId: "G-Y2Y2JLC18Y"
}
firebase.initializeApp(firebaseConfig)


// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
db.settings({timestampsInSnapshots: true})

// collection references
const usersCollection = db.collection('users')
const blogpostsCollection = db.collection('blogposts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
console.log(usersCollection)
// export utils/refs
export {
  db,
  auth,
  storage,
  usersCollection,
  blogpostsCollection,
  commentsCollection,
  likesCollection
}