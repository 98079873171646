<template>
  <div class="flex bg-gray-900 p-2 flex-wrap items-center justify-between text-gray-100 w-full">
    <div class=" flex">
      
      <router-link to="/">
        <img class="h-12" src="@/assets/Logo-NB-DEV.svg" alt="">
      </router-link>
     </div>
     <div class="flex hidden xl:block w-auto ">
        <router-link to="/blog" class="px-3 ">Blog</router-link>
        <router-link to="/about"  class="px-3">About</router-link>
      </div>

      <div  class="self-center  hidden xl:block">
        <router-link v-if="userProfile" to="/login">Login</router-link>
        <div v-if="!userProfile" class="flex"> 
          <router-link to="/createPost" class="px-3"> 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </router-link>
          <a  @click="logout()"  class="px-3">Logout</a>
        </div>
        
      </div>
  </div>
</template>

<script>
export default {
  computed:{
    userProfile(){
      return this.$store.state.userProfile
    }
  },
  methods:{
      logout() {
      this.$store.dispatch('logout')
    },
  }

}
</script>

<style>

</style>