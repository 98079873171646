<template>
  <Blogs></Blogs>
</template>

<script>
import Blogs from '@/components/Blogs'
export default {
  components: {
   Blogs
  },

}
</script>

<style>

</style>