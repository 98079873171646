<template>
      <div class="bg-yellow-600 grid grid-cols-1  xl:grid-cols-3 md:gap-20 py-12  ">
      <div v-for="post in posts" :key="post.id" class="bg-white overflow-hidden shadow-lg rounded-lg border-gray-100 border-1 relative  max-w-max ">
        <router-link :to="`/post/${post.id}`"  class="">
        <img v-if="post.details.titleImage" class="w-full h-48 md:h-32 object-cover" :src="post.details.titleImage" alt="" />
        <div class="font-bold text-3xl border-b-2 border-gray-600 px-8 py-4" >{{post.details.headline}}</div>
        <p class="text-grey-darker text-base px-8  text-" :inner-html.prop="post.details.post| truncate(250)"></p>
        </router-link>
      </div>
    </div>
</template>

<script>
export default {
  mounted () {
   this.$store.dispatch('getPosts')
  },
  computed: {
    posts(){return this.$store.state.posts}
  }
}
</script>
<style>

</style>