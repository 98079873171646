import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { auth } from './firebase'


Vue.filter("truncate", function(value, length) {
  if (!value) return "";
  value = value.toString();
  if (value.length > length) {
      return value.substring(0, length) + "...";
  } else {
      return value;
  }
});
Vue.config.productionTip = false

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
          router,
          store,

  render: h => h(App)
}).$mount('#app')
}
if (user) {
  store.dispatch('fetchUserProfile', user)
}
})
