<template>
<div class="bg-yellow-600">
   <router-link :to="`/createpost/${post.id}`"  class="btn_white absolute top-20 left-5 ">
           <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
      </router-link>

    <div class="flex content-start pt-12">

     <div class="w-full  md:w-2/3 bg-white mx-8  shadow-lg rounded-lg border-gray-100 border-1 p-4"> 
        <img v-if="post.details.titleImage" class="w-full h-48 md:h-32 object-cover border-0" :src="post.details.titleImage" alt="" />
        <div class="font-bold text-3xl px-8  pb-2 flex justify-center  items-center border-b-2 border-gray-600 h-32" >{{post.details.headline}}</div>
        <div class="mb-4 text-gray-500" > {{post.createdOn.toDate().toLocaleString('de-DE') }}</div>
        
      <div class="">
        <span v-html="post.details.post" class="bg-yellow-900"></span>
      </div>
    </div>
    <!-- <div class="hidden md:block md:w-1/3 bg-gray-100 shadow-lg rounded-lg border-gray-100 border-1 p-4"> 
      dsfsdfd
    </div> -->
  </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
mounted () {
   this.$store.dispatch('getPosts')
  },
computed: {
  ...mapGetters([
    'getPost'
  ]),
  post() {
    return this.getPost(this.$route.params.id)
  }
}
}
</script>

<style>

</style>