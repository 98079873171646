<template>
  <div class="bg-gray-100 m-10 p-5">
      <h1 class="text-3xl">Impressum</h1>

        <h2 class="text-2xl">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Nico Boettge<br />
        Stichterstra&szlig;e 7<br />
        55425 Waldalgesheim</p>
        <br>
        <h2 class="text-2xl">Kontakt</h2>
        <p>Telefon: 067219498955<br />
        E-Mail: info@dev-nb.de</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
        <br>
        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <br>
        <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>