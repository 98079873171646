<template>
  <div id="login" class="bg-gray-100 rounded-lg shadow-lg m-8 py-4 flex justify-center">
    <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset>
    <section>
      <div :class="{ 'signup-form': !showLoginForm }" class="col2">
        <form v-if="showLoginForm" @submit.prevent>
          <h1 class="text-xl bold pb-2">Anmelden</h1>
          <div>
            <label for="email1" class="mw_inputeLabe">Benutzername/E-Mail</label>
            <input v-model.trim="loginForm.email" type="text" placeholder="you@dev-NB.de" id="email1" class="mw_inpute" />
          </div>
          <div>
            <label for="password1"  class="mw_inputeLabe">Password</label>
            <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password1" class="mw_inpute" />
          </div>
          <button @click="login()" class="btn">Anmelden</button>
          <div class="flex">
            <a @click="togglePasswordReset()" class="btn">Passwort vergessen</a>
            <a @click="toggleForm()" v-if="!showLoginForm" class="btn hidden">Benutzer erstellen</a>
          </div>
        </form>
        <form v-else @submit.prevent>
          <h1>Benutzer anlegen</h1>
          <div>
            <label for="name" class="mw_inputeLabe">Name</label>
            <input v-model.trim="signupForm.name" type="text" placeholder="Benutzer Name" id="name" class="mw_inpute" />
          </div>
          <div>
            <label for="email2" class="mw_inputeLabe"> Email</label>
            <input v-model.trim="signupForm.email" type="text" placeholder="you@email.com" id="email2" class="mw_inpute" />
          </div>
          <div>
            <label for="password2" class="mw_inputeLabe">Password</label>
            <input v-model.trim="signupForm.password" type="password" placeholder="min 6 characters" id="password2" class="mw_inpute" />
          </div>
          <button @click="signup()" class="btn">Sign Up</button>
          <div class="btn">
            <a @click="toggleForm()">Back to Log In</a>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>


import PasswordReset from '@/components/auth/PasswordReset'
export default {
  components: {
    PasswordReset
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        name: '',
        title: '',
        email: '',
        password: ''
      },
      showLoginForm: true,
      showPasswordReset: false
    }
  },
  methods: {

    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
    },
    signup() {
      this.$store.dispatch('signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        name: this.signupForm.name,
        title: this.signupForm.title
      })
    }
  }
}
</script>