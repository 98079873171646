import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'

import * as fb from '../firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    posts:[],
    userProfile: {}
  },
  mutations: {
    //Blog management 
    updatePosts(state, payload){
      state.posts = payload
    },
    addPost(state, payload){
      state.posts.push(payload)
    },
    // User management 
    setUserProfile(state, val) {
      state.userProfile = val
    },

    donothing(){

    }
  },
  getters: {
    getPost: (state) => (id)=> {
      var filterObj = state.posts.filter(function(e) {
        return e.id == id;
      });
      console.log(id);
      console.log(filterObj);
    return filterObj[0];

      
    }
  },
  actions: {
    //load blog posts
    async getPosts({ commit }) {
      var blogPostDocs = []
      var blogPostDoc={}
      const blogPostData = await fb.blogpostsCollection.get()
      blogPostData.forEach((doc)=>{
        blogPostDoc = doc.data()
        blogPostDoc.id = doc.id
        blogPostDocs.push(blogPostDoc)
      })

      commit('updatePosts', blogPostDocs)
      //console.log(realestatesData.docs.map(doc => doc.data()))
    },
    async createBlogPost({ dispatch  }, blogPost) {
      // create post in firebase
   
     console.log(blogPost)
     if(blogPost.id){
      await fb.blogpostsCollection.doc(blogPost.id).set({
        details: blogPost.details
      },{ merge: true })    
     }else{
      await fb.blogpostsCollection.add({
        createdOn: new Date(),
        userId: fb.auth.currentUser.uid,
        details: blogPost.details
      })
     }

      dispatch('getPosts')

      router.push('/blog')
    },
    async uploadImage({commit},upload){
      return new Promise((resolve, reject) =>{
        try {
          let file = upload;
          //var imageName = uuid.v1() + "." +file.type.split('/').pop();
          console.log(file.name)
          commit('donothing')
          const metadata = { contentType: upload.type };
          const storagteRef= fb.storage.ref();
          const imageRef = storagteRef.child(`images/${file.name}`)
          imageRef.put(file, metadata).then(()=>{
            console.log("Upload complete")
          imageRef.getDownloadURL().then((downloadURL)=>{
              
            resolve(downloadURL)
            })
          }).catch(err => {
            console.log(err)
          }); 
   
        } catch (error) {
          reject(error);
        }
      })
  
    },
    async uploadTitleImage(context,upload){
      return new Promise((resolve, reject) =>{
        try {
          let file = upload;
          //var imageName = uuid.v1() + "." +file.type.split('/').pop();
          console.log(file.name)
          const metadata = { contentType: upload.type };
          const storagteRef= fb.storage.ref();
          const imageRef = storagteRef.child(`title/${file.name}`)
          imageRef.put(file, metadata).then(()=>{
            console.log("Upload complete")
          imageRef.getDownloadURL().then((downloadURL)=>{
              
            resolve(downloadURL)
            })
          }).catch(err => {
            console.log(err)
          }); 
   
        } catch (error) {
          reject(error);
        }
      })
  
    },
    //User Management
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
      // fetch user profile and set in state
      
      dispatch('fetchUserProfile', user)
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      // redirect to login view
      router.push('/login')
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.db.collection("users").doc(user.id).get()
   
      // set user profile in state
      commit('setUserProfile', userProfile.data())
      
      // change route to dashboard
      
        router.push('/')

    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
    
      // create user profile object in userCollections
      await fb.db.collection("users").doc(user.uid).set({
        name: form.name,
        title: form.title
      })
    
      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    }
  },
 

})
