<template>
  <div id="app" class="font-body bg-yellow-600 text-gray-900 flex flex-col h-screen justify-between ">
        <Navbar></Navbar>

    <router-view/>
    <Footer></Footer>
  </div>
</template>


<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
export default {
    components: {
    Navbar,
    Footer
  },
}
</script>
