<template>
  <div class=" text-gray-900">
    <!--  
  <div class="relative h-96">
        <img src="@/assets/titleimage.jpg" alt="" class="object-cover h-96 w-full absolute filter sepia blur-sm ">
    <div class="flex flex-col absolute top-5 left-10">
        <div class="text-7xl font-bold  text-gray-100">
          Welcome
        </div>
        <div class="text-gray-100 ">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
        </div>

    </div>
</div>
-->
<div class="h-96 flex justify-between p-8 bg-gray-300">

        <div class="flex flex-col justify-around  text-7xl font-bold text-gray-800">
            <div class="mx-10 top-3 left-2 transition duration-500 ease-in-out hover:text-yellow-600 transform hover:-translate-y-2 hover:scale-110">
              Coding
            </div>
            <div class="mx-24 transition duration-500 ease-in-out hover:text-yellow-600 transform hover:-translate-y-2 hover:translate-x-20 hover:scale-110">
              Design 
            </div>
            <div class=" mx-16 transition duration-500 ease-in-out hover:text-yellow-600 transform hover:-translate-y-2 hover:translate-x-20 hover:scale-110">
              Data 
            </div>
        </div>
  <img class="hidden xl:block md:h-96" src="@/assets/code.svg" alt="">
   
</div>
  <Blogs></Blogs>
  </div>
</template>

<script>
import Blogs from '@/components/Blogs'
export default {
  components: {
   Blogs
  },
  mounted () {
   this.$store.dispatch('getPosts')
  },
  computed: {
    posts(){return this.$store.state.posts}
  }
}
</script>

<style scoped>

</style>